.dashboard {
    padding-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.leftSideDashboard {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1;
}

.rightSideDashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 25%;
}

.buttonsDashboard {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    /* justify-content: center; */
}

.buttonsCollective {
    display: flex;
    align-items: center;
    gap: 20px;
    /* width: 100%; */
}

.payInOut {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

@media screen and (max-width: 650px) {
    .buttonsDashboard {
        justify-content: center;
        gap: 8px;
    }
    .buttonsCollective {
        width: 100%;
        gap: 8px;
    }
}