.notification {
    position: absolute;
    top: 40px;
    right: 20px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 20px 20px;
    z-index: 2;
    border-radius: 16px;
    /* transform: translateX(100px); */
    animation: changeX 3s forwards;
}

@keyframes changeX {
    0% {
        transform: translateX(150%);
    }

    25% {
        transform: translateX(-30px);
    }

    50% {
        transform: translateX(-30px);
    }

    90% {
        transform: translateX(-30px);
    }

    100% {
        transform: translateX(150%);
    }
}

.notification p {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
}

@media screen and (max-width: 768px) {
    .notification {
        right: 50%;
        padding: 12px 12px;
        width: 80%;
        text-align: center;
    }
    @keyframes changeX {
        0% {
            transform: translateX(300%);
        }
    
        25% {
            transform: translateX(50%);
        }
    
        50% {
            transform: translateX(50%);
        }
    
        90% {
            transform: translateX(50%);
        }
    
        100% {
            transform: translateX(300%);
        }
    }
}