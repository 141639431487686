.loader-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  
  .loader-spinner {
    border: 4px solid #cccccc;
    border-top: 4px solid transparent; 
    border-radius: 50%;
    animation: spin 1s linear infinite;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .loader-spinner::before {
    content: '';
    position: absolute;
    top: -4px; 
    left: -4px;
    right: -4px;
    bottom: -4px;
    /* border: 4px solid transparent; */
    /* border-top: 4px solid transparent; */
    border-radius: 50%;
    background: conic-gradient(from 0deg, #FF7700 0%, #E10CFF 50%, #FF7700 100%);
    -webkit-mask: radial-gradient(circle, transparent 60%, #000 60%); 
    mask: radial-gradient(circle, transparent 60%, #000 60%); 
    animation: spin 1s linear infinite; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }