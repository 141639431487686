.headerContainer {
    position: relative;
    z-index: 1;
    padding-top: 30px;
}

.header {
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    height: 80px;
    position: relative;
    transition: all 150ms ease-in-out;
    z-index: 2;
    overflow: hidden;
    /* Чтобы скрыть navContainerMobile под ним */
}

.header.open {
    border-radius: 30px 30px 0 30px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.15s ease-in-out;
}

.logoLink {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.logoLink:hover {
    transform: scale(1.1);
}

.logo {
    width: 160px;
}

.links {
    display: flex;
    align-items: center;
    gap: 25px;
}

.navLink {
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: #757575;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
}

.navLink:hover {
    transform: scale(1.1);
}

.navLink.active {
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}


.rightSideHeader {
    display: flex;
    align-items: center;
    gap: 30px;
}

.profileInfoHeader {
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 18px;
    gap: 12px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.profileInfoHeader:hover {
    transform: scale(1.1);
}

.profileInfoHeader p.active {
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.burgerIconContainer {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 0.15s ease;
}

.burgerIconContainer:hover {
    transform: scale(1.1);
}

.burgerImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;
}

.fadeOut {
    opacity: 0;
}

.navContainerMobile {
    position: absolute;
    right: 0;
    top: 0;
    /* Начальное положение под header */
    box-shadow: 0px 4px 12px 0px #00000014;
    background: #fff;
    padding: 20px 30px;
    border-radius: 0 0 30px 30px;
    z-index: 1;
    transition: all 300ms ease-in-out;
    /* Увеличенная длительность для плавности */
}

.toShow {
    animation: slideOut 300ms forwards;
}

@keyframes slideOut {
    0% {
        top: 0;
        /* Под header */
        opacity: 0;
    }

    100% {
        top: 100%;
        /* Выезжает вниз из-под header */
        opacity: 1;
    }
}

.toHide {
    animation: slideIn 300ms forwards;
}

@keyframes slideIn {
    0% {
        top: 100%;
        /* Видимый ниже header */
        opacity: 1;
    }

    100% {
        top: 0;
        /* Скрывается под header */
        opacity: 0;
    }
}

@media screen and (max-width: 1400px) {
    .header {
        overflow: hidden;
    }

    .navContainerMobile {
        box-shadow: 0px 8px 12px 0px #00000000;
        top: 0;
        /* Начальное положение под header */
        height: fit-content;
    }

    .links {
        flex-direction: column;
        align-items: flex-start;
    }

    .toShow {
        animation: slideOut 300ms forwards;
    }

    @keyframes slideOut {
        0% {
            top: 0;
            opacity: 0;
        }

        50% {
            top: 100%;
            opacity: 1;
        }

        100% {
            z-index: 2;
            top: 100%;
            opacity: 1;
        }
    }

    .toHide {
        animation: slideIn 300ms forwards;
    }

    @keyframes slideIn {
        0% {
            top: 100%;
            opacity: 1;
            z-index: 1;
        }

        /* 50% {

      } */
        100% {
            top: 0;
            opacity: 0;
            z-index: 1;
            pointer-events: none;
        }
    }
}

@media screen and (min-width: 1750px) {
    .navContainerMobile {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .header {
        border-radius: 10px;
    }

    .header.open {
        border-radius: 30px 30px 0 30px;
    }

    .navContainerMobile {
        border-radius: 0 0 10px 10px;
    }
}