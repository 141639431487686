.filterContainer {
    background: #fff;
    border: 1px solid #A0A0A0;
    box-shadow: 0px 4px 12px 0px #00000014;
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.filterBtnContainer {
    display: flex;
    gap: 12px;
    align-items: center;
}

.filterBtnContainer button {
    position: relative;
    height: 44px;
    font-size: 18px;
    color: #2B2B2A;
    cursor: pointer;
    transition: all 150ms ease-in-out;
}

.filterBtnContainer button.active {
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.filterBtnContainer button:hover {
    transform: scale(1.1);
}

.filterBtnContainer button:first-child {
    padding-left: 12px;
}
.filterBtnContainer button:last-child {
    padding-right: 12px;
}

.spacer {
    height: 24px;
    width: 1px;
    background: #A0A0A0;
}

@media screen and (max-width: 700px) {
    .filterContainer {
        width: 100%;
        justify-content: space-evenly;
    }
    .filterBtnContainer {
        justify-content: center;
    }
    .filterBtnContainer button {
        font-size: 14px;
    }
    .filterBtnContainer button:first-child {
        padding-left: 0;
    }
    .filterBtnContainer button:last-child {
        padding-right: 0;
    }
    .spacer {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .filterBtnContainer button {
        font-size: 12px;
    }
}
