.searchContainer {
    border: 0.5px solid #A0A0A0;
    padding: 0 16px;
    height: 38px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 16px;
    width: auto;
}

.pagesSearch {
    background: #fff; 
    height: 44px; 
    border-radius: 100px; 
    box-shadow: 0px 4px 12px 0px #00000014;
    border: 1px solid #A0A0A0;
    width: 266px;
}

.searchContainer input {
    font-size: 16px;
    color: #757575;
    width: 100%;
}


