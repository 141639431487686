.headerBlock {
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
    transition: all 150ms ease-in-out;
    overflow: hidden;
}

.headerTop {
    padding-bottom: 30px;
    border-bottom: 1px solid #A0A0A0;
    display: flex;
    justify-content: space-between;
}

.headerTop h1 {
    display: flex;
    font-size: 42px;
    font-weight: 600;
}

.headerRigth {
    display: flex;
    gap: 10px;
}

.headerBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}

.headerBottom span {
    color: #757575;
}

.headerBottom .status {
    display: flex;
    gap: 15px;
    align-items: center;
}

.headerBottom .statusIco img {
    width: 32px;
    height: 38px;
    object-fit: contain;
}

.headerBottom .statusText {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.headerBottom .statusText p {
    color: #2B2B2A;
    font-weight: 600;
    font-size: 24px;
}

.headerBottom .statusText span {
    font-weight: 400;
    font-size: 14px;
}

.headerBottom .amount {
    display: flex;
    gap: 20px;
}

.pageInfo {
    display: flex;
    gap: 20px;
    width: 100%;
}

@media screen and (min-width: 1101px) and (max-width: 1500px) {
    .pageInfo {
        flex-wrap: wrap;
    }

    .pageInfo>*:nth-child(1),
    .pageInfo>*:nth-child(2) {
        flex: 1;
        min-width: 0;
    }

    .pageInfo>*:nth-child(3) {
        flex: 0 0 calc(100% - 60px);
    }
}

@media screen and (max-width: 1100px) {
    .headerBottom {
        width: 100%;
        flex-direction: column;
        gap: 5px;
    }

    .headerBottom .status,
    .headerBottom .amount,
    .headerBottom .file {
        width: 100%;
        justify-content: space-between;
    }

    .headerBottom .status,
    .headerBottom .amount {
        margin-bottom: 10px;
    }

    .headerBottom .file {
        display: flex;
        align-items: center;
    }

    .pageInfo {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 900px) {
    .headerBlock {
        border-radius: 10px;
        padding: 20px;
    }

    .headerTop {
        flex-direction: column;
        gap: 5px;
        padding-bottom: 10px;
    }

    .headerRigth {
        justify-content: space-between;
    }

    .headerTop h1 {
        font-size: 28px;
    }

    .headerBottom {
        gap: 0px;
        margin-top: 5px;
    }

    .headerBottom .file span {
        font-size: 14px;
    }

}