.disputesDashboard {
    width: 100%;
    display: flex;
    position: relative;
    flex: 1;
}

.disputes {
    flex: 1;
}

.titleDisputes {
    font-size: 42px;
    color: #2B2B2A;
}

.tableSmall {
    position: relative;
}

@media screen and (max-width: 1774px) {
    .disputesDashboard {
        flex: auto;
    }
}