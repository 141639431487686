.App {
    padding: 0 40px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1400px) {
    .App {
        padding: 0 20px;
    }
}

@media screen and (max-width: 576px) {
    .App {
        padding: 0 16px;
    }
}