.notFoundPage {
    position: absolute;
    background: #F6F6FA;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    overflow: hidden;
}

.notFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 720px;
}

.notFoundSvg {
    width: 100%;
    padding-bottom: 3vh;
}

.notFoundMainText {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1vh;
    padding-bottom: 2vh;
}

.notFoundPage h1 {
    color: #2B2B2A;
    font-weight: 700;
    font-size: 42px;
}

.notFoundText {
    color: #757575;
    font-size: 32px;
}

@media screen and (max-width: 1200px) {
    .notFoundContainer {
        width: 540px;
    }
    .notFoundPage h1 {
        font-size: 32px;
    }
    .notFoundText {
        color: #757575;
        font-size: 24px;
    }
}
@media screen and (max-width: 450px) {
    .notFoundContainer {
        width: 80%;
    }
    .notFoundPage h1 {
        font-size: 24px;
    }
    .notFoundText {
        color: #757575;
        font-size: 14px;
    }
}
