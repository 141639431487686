.disputeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disputeModal {
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 40px 30px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60vw;
}

.disputeTitle {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.disputeTitle h1 {
    color: #2B2B2A;
    font-weight: 600;
    line-height: 30px;
}

.disputeTitle p {
    color: #757575;
    font-size: 24px;
    line-height: 25px;
}

.disputeWallet {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.disputeWallet h4 {
    background: linear-gradient(190deg, #FF7700 85%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 24px;
}

.disputeText {
    color: #2B2B2A;
    font-size: 24px;
    line-height: 26px;
}

.valueContainer {
    display: flex;
    justify-content: space-between;
    border: 1px solid #A0A0A0;
    background: #F6F6FA;
    border-radius: 16px;
    padding: 15px 20px;
    align-items: center;
}

.valueText {
    color: #757575;
    font-size: 24px;
    font-weight: 600;
    line-height: 17px;
}

.sumFordispute {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: left;
}

.selectWD {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #A0A0A0;
    border-radius: 16px;
    padding: 15px 20px;
    transition: all 150ms ease-in-out;
    cursor: pointer;
}

.inputWD {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #A0A0A0;
    border-radius: 16px;
    transition: all 150ms ease-in-out;
}

.inputWD input {
    font-size: 24px;
    font-weight: 600;
    color: #2B2B2A;
    padding: 10.5px 20px 10.5px 20px;
    width: 100%;
}

.inputWD input[type="number"] {
    -moz-appearance: textfield; /* Для Firefox */
    appearance: textfield; /* Для других браузеров */
}

.inputWD input[type="number"]::-webkit-inner-spin-button,
.inputWD input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.usdtContainer {
    padding-right: 20px;
}

.selectWD:hover {
    background: rgb(0, 0, 0, 0.1);
}

.selectWD.active {
    cursor: default;
    background: #F6F6FA;
}

.selectWD p {
    color: #757575;
    transition: all 150ms ease-in-out;
    font-size: 24px;
    font-weight: 600;
    line-height: 17px;
}

.qrContainer {
    display: flex;
    border: 1px solid #A0A0A0;
    padding: 20px;
    border-radius: 16px;
    width: fit-content;
}

.disputeTextWarning {
    font-size: 14px;
    color: #757575;
    line-height: 17px;
}

.disputeButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.usdtContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #2B2B2A;
}

.warning {
    font-size: 14px;
    line-height: 13px;
    color: #EB001B;
}

.dataCorrect {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.dataCorrect p,
.dataSelect p {
    font-size: 24px;
    color: #2B2B2A;
    line-height: 17px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;
}

.dataSelect {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dataSelect select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px 32px 10px 12px; /* Учитываем место для кастомной стрелки */
    font-size: 14px;
    color: #555;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease;
    position: relative;
}

/* Кастомная стрелка */
.dataSelect select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
}

/* Состояние при наведении */
.dataSelect select:hover {
    border-color: #bbb;
    background: #fff;
}

/* Состояние при фокусе */
.dataSelect select:focus {
    border-color: #FF7700;
    box-shadow: 0 0 0 2px rgba(255, 119, 0, 0.2);
}

/* Стили для опций (работают ограниченно в разных браузерах) */
.dataSelect select option {
    background: #fff;
    color: #333;
}

.inputWallet input {
    font-weight: 400;
}

@media screen and (max-width: 1200px) {
    .disputeModal {
        padding: 30px;
        gap: 25px;
    }
    .disputeTitle {
        gap: 15px;
    }
    .disputeTitle h1 {
        font-size: 32px;
    }
    .disputeTitle p {
        font-size: 18px;
    }
    .disputeWallet {
        gap: 5px;
    }
    .disputeText {
        font-size: 18px;
    }
    .valueText {
        font-size: 18px;
    }
    .sumFordispute {
        gap: 15px;
    }
}

@media screen and (max-width: 780px) {
    .disputeModal {
        gap: 16px;
        border-radius: 10px;
        width: 80vw;
        
    }
    .disputeTitle {
        gap: 2px;
    }
    .disputeTitle h1 {
        font-size: 24px;
    }
    .disputeTitle p,
    .dataCorrect p,
    .inputWD input {
        font-size: 14px;
    }

    .disputeWallet {
        gap: 2px;
    }
    .disputeText {
        font-size: 14px;
    }
    .valueText {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sumFordispute {
        gap: 2px;
    }
    .valueContainer {
        padding: 12px 16px;
    }
}

/* @media screen and (max-width: 650px) {
    .disputeModal {
        position: absolute;
        top: 10px;
    }
} */

@media screen and (max-width: 578px) {
    .disputeTextWarning {
        font-size: 10px;
    }
}

@media screen and (max-width: 370px) {
    .disputeOverlay {
        overflow: auto;
    }
    .disputeModal {
        top: 0;
    }
}
