.standarts {
    border: 1px solid #A0A0A0;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 20px 16px;
    border-radius: 100px;
    background: #A0A0A0;
    color: white;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    transition: all 150ms ease-in-out;
}

.standarts:disabled {
    cursor: not-allowed;
}

.colored {
    background: linear-gradient(185.4deg, #FF7700 6.42%, #E10CFF 93.77%);
}

.colored:hover {
    transform: scale(1.02);
}

.white {
    background: rgb(255, 255, 255, 1);

}

.white:hover {
    background: rgb(0, 0, 0, 0.1);
    transform: scale(1.02);
}

.white p{
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    -webkit-background-clip: text;
    background-clip: text; 
    -webkit-text-fill-color: transparent;
}

.forPages p {
    background: none;
    color: #2B2B2A;
    -webkit-background-clip: inherit;
    background-clip: inherit; 
    -webkit-text-fill-color: inherit;
    font-weight: 400;
}

.coloredBorder {
    border: none;
}

.coloredBorder::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 2px 2px;
    background: linear-gradient(190deg, #FF7700 50%, #E10CFF 80%);
    z-index: -1;
}

@media screen and (max-width: 370px) {
    .standarts {
        font-size: 12px;
        gap: 4px;
    }
}