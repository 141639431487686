@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #F6F6FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: none;
  background: none;
}

input {
  border: none;
  background: none;
}

input:focus {
  outline: none;
}

img {
  pointer-events: none;
}