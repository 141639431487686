.topUpOverlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topUpModal {
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 40px 30px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60vw;
}

.topUpTitle {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.topUpTitle h1 {
    color: #2B2B2A;
    font-weight: 600;
    line-height: 30px;
}

.topUpTitle p {
    color: #757575;
    font-size: 24px;
    line-height: 25px;
}

.wallet {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.topUpText {
    color: #2B2B2A;
    font-size: 24px;
    line-height: 26px;
}

.valueContainer {
    display: flex;
    justify-content: space-between;
    border: 1px solid #A0A0A0;
    background: #F6F6FA;
    border-radius: 16px;
    padding: 15px 20px;
    align-items: center;
}

.valueText {
    color: #757575;
    font-size: 24px;
    font-weight: 600;
    line-height: 17px;
}

.sumForTopUp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: left;
}

.qrContainer {
    display: flex;
    border: 1px solid #A0A0A0;
    padding: 20px;
    border-radius: 16px;
    width: fit-content;
}

.topUpTextWarning {
    font-size: 14px;
    color: #757575;
    line-height: 17px;
}

.topUpButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 1200px) {
    .topUpModal {
        padding: 30px;
        gap: 25px;
    }
    .topUpTitle {
        gap: 15px;
    }
    .topUpTitle h1 {
        font-size: 32px;
    }
    .topUpTitle p {
        font-size: 18px;
    }
    .wallet {
        gap: 15px;
    }
    .topUpText {
        font-size: 18px;
    }
    .valueText {
        font-size: 18px;
    }
    .sumForTopUp {
        gap: 15px;
    }
}

@media screen and (max-width: 780px) {
    .topUpModal {
        gap: 8px;
        border-radius: 10px;
        width: 80vw;
    }
    .topUpTitle {
        gap: 2px;
    }
    .topUpTitle h1 {
        font-size: 24px;
    }
    .topUpTitle p {
        font-size: 14px;
    }
    .wallet {
        gap: 2px;
    }
    .topUpText {
        font-size: 14px;
    }
    .valueText {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sumForTopUp {
        gap: 2px;
    }
    .valueContainer {
        padding: 12px 16px;
    }
}

@media screen and (max-width: 650px) {
    .topUpModal {
        position: absolute;
        top: 10px;
    }
}

@media screen and (max-width: 578px) {
    .topUpTextWarning {
        font-size: 10px;
    }
}

@media screen and (max-width: 370px) {
    .topUpOverlay {
        overflow: auto;
    }
    .topUpModal {
        top: 0;
    }
}