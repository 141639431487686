.universal-table {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    padding: 30px;
    border-radius: 30px;
}

.smallDispute table {
    font-size: 14px !important;
    width: 100%;
    min-width: 100%;
    border-collapse: collapse;
}

.smallDispute tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.smallDispute td:nth-child(2) {
    text-align: right;
}

.universal-table h1 {
    color: #2B2B2A;
    font-weight: 600;
    font-size: 42px;
    line-height: 30px;
    padding-bottom: 10px;
}

.table-wrapper {
    overflow: auto;
    padding-bottom: 25px;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 1100px;
}

th {
    padding-bottom: 20px;
    font-size: 24px;
    color: #757575;
    font-weight: 400;
    text-align: left;
}

/* Равная ширина для всех td, кроме .actions */
/* td:not(.actions) {
    width: calc(100% / var(--columns-count));
} */

td.actions {
    width: fit-content;
    position: relative;
}

tbody tr {
    text-align: left;
    border-bottom: 1px solid #A0A0A0;
    border-top: 1px solid #A0A0A0;
}

tbody tr:last-child {
    border-bottom: none;
}

.row {
    height: 64px;
    width: 100%;
}

.clickable-row:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.actions button {
    cursor: pointer;
}

.pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.paginationBtn {
    min-width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a0a0a0;
    border-radius: 30px;
    font-size: 18px;
    line-height: 13px;
    color: #757575;
    cursor: pointer;
    padding: 0 8px;
}

.pagination button.active {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    color: white;
    font-weight: 600;
    border: none;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pageNumbers {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.ellipsis {
    color: #757575;
    font-size: 18px;
    line-height: 13px;
}

.pageSwitch {
    color: #757575;
    font-size: 18px;
    border: 1px solid #A0A0A0;
    border-radius: 30px;
    padding: 0 20px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 13px;
    cursor: pointer;
}

.nullResult {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #757575;
    font-weight: 400;
    font-size: 24px;
    /* position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%; */
}


@media screen and (max-width: 1100px) {
    .pageSwitch {
        font-size: 14px;
    }

    .paginationBtn {
        font-size: 14px;
    }

    .ellipsis {
        font-size: 14px;
    }

    .universal-table h1 {
        font-size: 32px;
    }
}


@media (min-width: 901px) {
    .desktop-hidden {
        display: none;
    }
}


@media (max-width: 900px) {
    .table-wrapper {
        width: 100%;
        overflow-x: hidden;
    }

    table {
        max-width: 100%;
        min-width: 100%;
    }

    table th:nth-child(2),
    table td:nth-child(2) {
        width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    

    .rowContainer {
        width: fit-content;
    }

    .mobile-hidden {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .universal-table {
        gap: 10px;
        padding: 24px;
        border-radius: 10px;
    }

    .pageSwitch {
        font-size: 10px;
        padding: 0 10px;
        height: 25px;
    }

    .pageNumbers {
        gap: 4px;
    }

    .pagination {
        gap: 4px;
    }

    .paginationBtn {
        font-size: 10px;
        width: 25px;
        height: 25px;
    }

    .ellipsis {
        font-size: 10px;
    }

    th {
        padding-bottom: 10px;
        font-size: 14px;
    }
}