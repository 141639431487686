.rowText {
    align-items: center;
}

.rowText h4 {
    font-size: 18px;
    font-weight: 600;
    color: #2B2B2A;
}

.rowText p {
    font-size: 14px;
    color: #757575;
    display: flex;
    gap: 5px;
    align-items: center;
}

.rowText p img {
    width: 15px;
    height: 15px;
    border-radius: 10px;
}

.rowContainer {
    align-items: center;
}

.forRowIcon {
    width: 32px;
    height: 32px;
}

.forRowIcon img {
    width: 100%;
    height: 100%;
}

.progress {
    width: 60%;
    height: 10px;
    border: 1px solid #A0A0A0;
    border-radius: 30px;
    background-color: #fff;
    overflow: hidden;
}

/* Стили для WebKit-браузеров (Chrome, Safari) */
.progress::-webkit-progress-bar {
    background-color: #fff; 
    border-radius: 30px; 
}

.progress::-webkit-progress-value {
    background-color: #14CD36; 
    border-radius: 30px; /* Скругление заполнения */
    transition: width 0.150s ease; /* Плавная анимация */
}

/* Стили для Firefox */
.progress::-moz-progress-bar {
    background-color: #14CD36; /* Цвет заполненной части */
    border-radius: 30px; /* Скругление */
}

/* Для других браузеров (ограниченная поддержка) */
.progress[value] {
    appearance: none; /* Убираем стандартный стиль */
}

@media screen and (max-width: 1100px) {
    .rowText h4 {
        font-size: 14px;
    }
    .rowText p {
        font-size: 10px;
    }
}