.miniBlock {
    background: #fff;
    box-shadow: 0px 4px 12px 0px #00000014;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    position: relative;
    transition: all 150ms ease-in-out;
    overflow: hidden;
    flex: 1;
    height: fit-content;
}

.miniBlock h1 {
    font-weight: 600;
    font-size: 42px;
}

.miniBlockRows {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.miniBlockRow {
    display: flex;
    gap: 15px;
    align-items: center;
    border-bottom: 1px solid #A0A0A0;
    padding-bottom: 15px;
}

.miniBlockRow:last-child {
    padding-bottom: 0;
    border: none;
}

.miniBlockRowIcon {
    width: 40px;
    height: 40px;
    border: 1px solid #A0A0A0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.miniBlockRowIcon img.round {
    border-radius: 50%;
}

.miniBlockRowIcon img {
    width: 24px;
    height: 24px;
}

.miniBlockRowText {
    display: flex;
    flex-direction: column;
}

.miniBlockRowText span {
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

.miniBlockRowText p {
    color: #2B2B2A;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
}

@media screen and (max-width: 1100px) {
    .miniBlock {
        flex: none;
    }
}

@media screen and (max-width: 900px) {
    .miniBlock {
        border-radius: 10px;
        gap: 10px;
        padding: 20px;
    }

    .miniBlock h1 {
        font-size: 28px;
    }

    .miniBlockRows {
        gap: 5px;
    }

    .miniBlockRow {
        gap: 10px;
        padding-bottom: 10px;
    }

    .miniBlockRowText span {
        font-size: 12px;
        line-height: 12px;
    }

    .miniBlockRowText p {
        font-size: 18px;
        line-height: 18px;
    }
}