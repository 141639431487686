.loginPage {
    background: #F6F6FA;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginForm {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 40px 30px;
    border-radius: 30px;
    box-shadow: 0px 4px 12px 0px #00000014;
    gap: 20px;
    width: 40vw;
}

.loginInput {
    background: #F6F6FA;
    border-radius: 16px;
    padding: 10px;
    font-size: 32px;
    color: #757575;
    font-weight: 500;
    border: 1px solid #A0A0A0;
}

.textInLogin {
    font-size: 32px;
}

@media screen and (max-width: 1400px) {
    .loginInput, .textInLogin {
        font-size: 24px;
    }
}

@media screen and (max-width: 1100px) {
    .loginForm {
        width: 60vw;
    }
}

@media screen and (max-width: 700px) {
    .loginForm {
        width: 70vw;
        gap: 10px;
    }
    .loginInput, .textInLogin {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px){
    .loginInput, .textInLogin {
        font-size: 14px;
    }
}